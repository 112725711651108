import * as React from "react";
import Layout from "../components/layout/layout";
import { SeoEN } from "../components/seo";
import PlansConatiner from "../components/plansConatiner";
import "../assets/css/index.css";
import { translations } from "../locales/pricing.js";
import kolibirdBox from "../assets/img/buyInvitation/kolibirdBox.svg";

const PricingPage = () => {
  return (
    <Layout page="pricing" language="en">
      <main className=" pricing-page-container container">
        <div className="price-img-container">
          <img src={kolibirdBox} alt="buy invitation image" />
        </div>
        <div>
          <h1 className="price-page-title">{translations.en.pageTitle}</h1>
          <p>{translations.en.explanation1}</p>
          <p>{translations.en.explanation2}</p>
          <PlansConatiner language="en" page="true" />
        </div>
      </main>
    </Layout>
  );
};

export default PricingPage;

export const Head = () => <SeoEN hrefLangs={[{href: "https://kolibird.app/pricing/", lang: "es"}, {href: "https://kolibird.app/en/pricing/", lang: "en"}]}/>;
