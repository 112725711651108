import * as React from "react";
import { useState } from "react";
import Button from "./layout/Button";
import { translations } from "../locales/pricing.js";
import isEuropean from "../utils/findLocation.js";
import { translations as linksTranslations } from "../locales/links";
const pricesList = [
  { invitation: 20, euros: 19, dollars: 21.77 },
  { invitation: 40, euros: 39, dollars: 43.56 },
  { invitation: 60, euros: 59, dollars: 64.26 },
  { invitation: 80, euros: 79, dollars: 86.04 },
  { invitation: 100, euros: 99, dollars: 108.92 },
  { invitation: 120, euros: 119, dollars: 129.61 },
  { invitation: 140, euros: 139, dollars: 151.39 },
  { invitation: 160, euros: 159, dollars: 173.18 },
  { invitation: 180, euros: 179, dollars: 194.96 },
  { invitation: 200, euros: 199, dollars: 216.74 },
  { invitation: 225, euros: 219, dollars: 238.52 },
  { invitation: 250, euros: 239, dollars: 260.31 },
  { invitation: 275, euros: 259, dollars: 282.09 },
  { invitation: 300, euros: 279, dollars: 303.87 },
  { invitation: 500, euros: 450, dollars: 483.79 },
  { invitation: 700, euros: 600, dollars: 645.06 },
  { invitation: 1000, euros: 800, dollars: 860.08 },
];

export default function PlansConatiner({ language, page = false }) {
  const [planSelect, setPlanSelect] = useState();
  const [invitationNumbers, setInvitationNumbers] = useState(0);
  const [plans, setPlans] = useState(pricesList);
  const isEuroCurrency = isEuropean();

  const handleChange = (e) => {
    const value = e.target.value;
    setInvitationNumbers(value);
    const max = pricesList.sort((a, b) => {
      return b.invitation + a.invitation;
    })[pricesList?.length - 1];

    if (value == false) {
      return setPlans(pricesList);
    }
    if (value >= 0) {
      const plan = pricesList.find((plan) => {
        if (value > max.invitation && plan.invitation >= max.invitation) {
          return plan;
        }
        if (value <= plan.invitation) {
          return plan;
        }
      });
      setPlans([plan]);
    }
  };
  const selectInvitation = (plan, index) => {
    setPlanSelect(index);
    setInvitationNumbers(plan.invitation);
  };

  return (
    <section className="prices-container container">
      {!page && (
        <h2 className="title-section">
          {translations[language].title}
          <span className="text-stand-out-purple">Kolibird</span>
        </h2>
      )}
      {!page && <p>{translations[language].text}</p>}
      <div className="prices-table-list">
        <div className="prices-amount-container">
          <p>{translations[language].table}</p>
          <input
            type="number"
            step="1"
            min="0"
            className="input prices-amount-input"
            onChange={handleChange}
            value={invitationNumbers}
          />
        </div>
        <div className="prices-table-header table-prices">
          <p>{translations[language].titleTable1}</p>
          <p>{translations[language].titleTable2}</p>
        </div>
        <div className="prices-table-body-container">
          {plans?.map((plan, index) => {
            return (
              <div
                key={index}
                onClick={() => selectInvitation(plan, index)}
                className={`prices-table-body table-prices ${
                  index === planSelect && "plan-activate"
                }`}>
                <div className="table-invitation">
                  <p>
                    {translations[language].textTable1}
                    {plan?.invitation}
                    {translations[language].textTable2}
                  </p>
                </div>
                <div className="table-price">
                  <p>
                    {isEuroCurrency && plan ? plan?.euros : plan?.dollars}
                    {isEuroCurrency
                      ? translations[language].currencyEurope
                      : translations[language].currencyNotEurope}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
        <div className="prices-table-footer">
          <p>*{translations[language].footerTable}</p>
          <p>**{translations[language].footerTable2}</p>
        </div>
      </div>
      <Button
        text={translations[language].button}
        type="primary"
        className="button-start"
        onClick={() => {
          if (window.location.href.search("invitaciones-gratis") > -1) {
            window.location.href = `${linksTranslations[language].access[1]}prueba-kolibird-gratis?try=33349a11-5501-41e4-aeb4-f6ecb1a42ad1`;
          } else {
            window.location.href = linksTranslations[language].start[1];
          }
        }}
      />
    </section>
  );
}
