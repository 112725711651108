export const translations = {
  es: {
    title: "El precio de las invitaciones en ",
    text: "Enviar las invitaciones de forma digital te permite ahorrar costes, ahorrar tiempo y cuidar el medio ambiente. En Kolibird pagarás en función del número de invitaciones que envíes y podrás disfrutar de los siguientes paquetes:",
    table: "¿Cuántas invitaciones vas a enviar?",
    titleTable1: "Tamaño del Paquete",
    titleTable2: "Precio",
    currencyEurope: " euros",
    currencyNotEurope: " $",
    textTable1: "Hasta ",
    textTable2: " invitaciones",
    footerTable:
      "Si has obtenido un código de descuento, introdúcelo al pagar y aplicarás el descuento.",
    footerTable2:
      "Recuerda que al registrarte tendrás 5 invitaciones gratis para poder probar Kolibird.",
    button: "Empezar",
    pageTitle: "Los precios de las invitaciones de Kolibird",
    explanation1:
      "No pagarás por el número de invitados si no por el número de invitaciones enviadas, es decir, si en una invitación cuentas con 5 personas de una misma familia, únicamente pagarás por un envío ;)",
    explanation2:
      "Es normal que no tengas claro cuántas invitaciones vas a enviar. ¡No te preocupes! Selecciona un número estimado (intenta ir a mínimos) y luego siempre tendrás tiempo de realizar una nueva compra según el número de invitados finales.",
  },
  en: {
    title: "Invitations pricing on ",
    text: "Sending the invitations digitally allows you to save costs, save time and take care of the environment. At Kolibird you pay according to the number of invitations you send and you can enjoy the following packages:",
    table: "How many invitations will you send?",
    titleTable1: "Package Size",
    titleTable2: "Price",
    currency: " euros",
    textTable1: "Up to ",
    textTable2: " invitations",
    footerTable:
      "If you have obtained a discount code, enter it at checkout and the discount will be applied.",
    footerTable2:
      "Remember that when you register you will get 5 free invitations to try Kolibird.",
    button: "Get started",
    pageTitle: "Prices of Kolibird invitations",
    explanation1:
      "You will not pay for the number of guests but for the number of invitations sent, i.e. if you have 5 people from the same family on an invitation, you will only pay for one shipment ;)",
    explanation2:
      "It is normal that you are not sure how many invitations you are going to send, don't worry! Select an estimated number (try to go to a minimum) and then you will always have time to make a new purchase according to the final number of guests.",
  },
};
