const findLocation = () => {
  const location = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
  if (location) {
    return location;
  }
  return false;
};

const isEuropean = () => {
  const validLocation = [
    "Europe",
    "Canary",
    "Ceuta",
    "Madeira",
    "Azores",
    "Reykjavik",
    "Faroe",
  ];
  try {
    const location = findLocation();
    if (location) {
      const isEuropeanLocation = validLocation.some((validLoc) =>
        location.includes(validLoc)
      );
      return isEuropeanLocation;
    }
    /*Si hubiese algun error al intentar acceder al sistema o la ubicacion se devuelve true para por defecto sea siempre en Euros aunque falle */
    return true;
  } catch {
    return true;
  }
};

export default isEuropean;
